<template>
    <div>
        <b-container fluid class="z-background-dark text-light no-gutters px-0">
            <a class="anchor" id="benefits" href="#"></a>
            <driver-perks>
                <template v-slot:image>
                    <div id="image-1" class="driver-image"></div>
                </template>
            </driver-perks>
        </b-container>
        <b-container fluid class="z-background-light-grey pb-5 px-0">
            <a class="anchor" id="earnings" href="#"></a>
            <b-container>
                <b-row>
                    <b-col class="my-5">
                        <img
                            class="w-100 stats-image"
                            src="@/assets/img/driver-earnings-graph.png"
                            alt="Gráfica de ganancias de mensajero"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <driver-earnings />
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="my-5">
            <a class="anchor" id="requisites" href="#"></a>
            <b-container class="requisites py-2">
                <b-row class="mt-md-5">
                    <b-col>
                        <h2 class="z-text-blue z-text-small text-md-left mb-3">
                            Requisitos
                        </h2>
                        <p class="z-text-bigger text-md-left mb-md-5">
                            Requisitos para mensajeros
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6" md="3">
                        <img
                            class="w-100"
                            src="@/assets/img/requirement-android.png"
                            alt="celular android"
                        />
                        <p>Celular Android</p>
                    </b-col>
                    <b-col cols="6" md="3">
                        <img
                            class="w-100"
                            src="@/assets/img/requirement-license.png"
                            alt="celular android"
                        />
                        <p>Licencia de motociclista vigente</p>
                    </b-col>
                    <b-col cols="6" md="3">
                        <img
                            class="w-100"
                            src="@/assets/img/requirement-motor.png"
                            alt="celular android"
                        />
                        <p>Motor de al menos 125 cc</p>
                    </b-col>
                    <b-col cols="6" md="3">
                        <img
                            class="w-100"
                            src="@/assets/img/requirement-fisco.png"
                            alt="celular android"
                        />
                        <p>Registrado en hacienda <span class="z-text-blue">(opcional)</span></p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="z-background-light-grey py-5 testimonials">
            <a class="anchor" id="testimonials" href="#"></a>
            <b-container class="py-2">
                <b-row class="mt-md-5">
                    <b-col>
                        <h2 class="z-text-blue z-text-small text-md-left mb-3">
                            Testimonios
                        </h2>
                        <p class="z-text-bigger text-md-left mb-md-5">
                            Conoce la familia Zubut
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <drivers-testimonials-carousel />
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-row>
            <b-col md="6" class="p-0">
                <div id="image-2" class="driver-image"></div>
            </b-col>
            <b-col
                class="z-background-dark form-side d-flex justify-content-center align-items-center p-5 px-md-0"
                md="6"
            >
                <div class="form-section-container d-md-flex justify-content-md-end p-md-3 ">
                    <div class="form-section text-md-left p-md-5">
                        <h3
                            style="font-size: 12px; font-weight: 600; letter-spacing: 1px;"
                            class="h6 text-uppercase z-text-light z-text-bold"
                        >
                            Conviértete en mensajero
                        </h3>

                        <a
                            href="https://play.google.com/store/apps/details?id=com.zubut"
                            target="_blank"
                            @click="handleClick"
                        >
                            <z-button size="lg" uppercase bold>
                                Regístrate en Zubut
                            </z-button>
                        </a>
                    </div>
                </div>
            </b-col>
        </b-row>
        <press-bar class="mb-5" />
    </div>
</template>

<script>
import DriverEarnings from "@/components/DriverEarnings";
import DriverPerks from "@/components/DriverPerks";
import DriversTestimonialsCarousel from "@/components/DriversTestimonialsCarousel";
import PressBar from "@/components/PressBar";
import trackActionWithAnalytics from "@/utils/track-Action-With-Analytics.js";

export default {
    name: "ForDrivers",
    metaInfo: {
        title: "Para mensajeros",
        meta: [
            {
                name: "description",
                content: `Obtén más ingresos con los horarios que tú elijas.`
            }
        ]
    },
    components: {
        DriverEarnings,
        DriverPerks,
        DriversTestimonialsCarousel,
        PressBar
    },
    methods: {
        handleClick() {
            trackActionWithAnalytics.facebook("Lead", "registrate mensajero");
            trackActionWithAnalytics.google("link", "registrate mensajero");
        }
    }
};
</script>

<style lang="scss" scoped>
.driver-image {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#image-1 {
    height: 200px;
    background-image: url("../assets/img/drivers-5.jpeg");
    background-position-y: 0px;
}

#image-2 {
    height: 100%;
    background-image: url("../assets/img/drivers-6-w800.jpg");
}

.testimonials {
    height: 1080px;
}

@media only screen and (min-width: 576px) {
    #image-1 {
        background-position-y: 0px;
    }

    .testimonials {
        height: auto;
    }
}

@media only screen and (min-width: 768px) {
    #image-1 {
        height: 300px;
    }
}

@media only screen and (min-width: 992px) {
    #image-1 {
        height: 400px;
    }

    .stats-image {
        width: 80% !important;
    }

    .testimonials {
        height: 713px;
    }
}

@media only screen and (min-width: 1200px) {
    #image-1 {
        height: 500px;
    }

    .requisites {
        img {
            width: 150px !important;
        }
    }
}
</style>
