<template>
    <div
        :class="[
            'driver-testimonial',
            'p-2',
            'pl-lg-4',
            'mb-4',
            'text-lg-left',
            { active: slide === testimonial.id }
        ]"
        @click="$emit('click', testimonial.id)"
    >
        <div class="ml-lg-4 mt-lg-3 mr-lg-2">
            <img class="quotes my-3" src="@/assets/icons/quotes-grey.svg" alt="quotes" />
            <p class="text mb-2">{{ testimonial.text }}</p>
            <p class="name font-weight-bold">- {{ testimonial.name }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "DriverTestimonialQuote",
    props: {
        slide: {
            type: Number,
            required: true
        },
        testimonial: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
$zubut-blue: #0077fe;

.driver-testimonial {
    position: relative;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: inset 0 -5px 0 0 $zubut-blue, 0 13px 12px 2px rgba(158, 198, 241, 0.25);
    transition: all 0.3s ease-in;
    visibility: visible;
    cursor: pointer;
    opacity: 1;

    &:hover,
    &.active {
        box-shadow: inset 0 -5px 0 0 $zubut-blue, 0 13px 12px 8px rgba(158, 198, 241, 0.35);
    }

    .quotes {
        height: 0.8em;
    }
}

@media only screen and (min-width: 992px) {
    .quotes {
        position: absolute;
        left: 1em;
        top: 0.5em;
    }
}
</style>
