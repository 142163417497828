var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'driver-testimonial',
        'p-2',
        'pl-lg-4',
        'mb-4',
        'text-lg-left',
        { active: _vm.slide === _vm.testimonial.id }
    ],on:{"click":function($event){return _vm.$emit('click', _vm.testimonial.id)}}},[_c('div',{staticClass:"ml-lg-4 mt-lg-3 mr-lg-2"},[_c('img',{staticClass:"quotes my-3",attrs:{"src":require("@/assets/icons/quotes-grey.svg"),"alt":"quotes"}}),_c('p',{staticClass:"text mb-2"},[_vm._v(_vm._s(_vm.testimonial.text))]),_c('p',{staticClass:"name font-weight-bold"},[_vm._v("- "+_vm._s(_vm.testimonial.name))])])])}
var staticRenderFns = []

export { render, staticRenderFns }