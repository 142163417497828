<template>
    <b-row>
        <b-col cols="12" lg="6" class="mb-5">
            <b-carousel
                id="drivers-testimonials-carousel"
                v-model="slide"
                :interval="3000"
                indicators
                img-width="1024"
                img-height="480"
            >
                <driver-testimonial-slide
                    v-for="(testimonial, index) in testimonials"
                    :key="`${index}-carousel-slide`"
                    :testimonial="testimonial"
                />
            </b-carousel>
        </b-col>
        <b-col cols="12" lg="6">
            <driver-testimonial-quote
                v-for="(testimonial, index) in testimonials"
                :key="`${index}-carousel-quote`"
                @click="selectQuote"
                :slide="slide"
                :testimonial="testimonial"
            />
        </b-col>
    </b-row>
</template>

<script>
import DriverTestimonialQuote from "./DriverTestimonialQuote";
import DriverTestimonialSlide from "./DriverTestimonialSlide";
import { BCarousel } from "bootstrap-vue";

export default {
    name: "DriversTestimonialsCarousel",
    components: {
        BCarousel,
        DriverTestimonialQuote,
        DriverTestimonialSlide
    },
    data() {
        return {
            slide: 0,
            testimonials: [
                {
                    id: 0,
                    text:
                        "No tengo nada más que agradecimiento con ustedes, es un ambiente laboral muy bueno.",
                    name: "Victor",
                    image: "victor-frias.jpg",
                    alt: "mensajero victor"
                },
                {
                    id: 1,
                    text: "Estoy muy agusto trabajando en zubut, hay un buen equipo de trabajo.",
                    name: "Jonathan",
                    image: "jonathan-hernandez.jpg",
                    alt: "mensajero jonathan"
                },
                {
                    id: 2,
                    text:
                        "Es una empresa comprometida con sus socios, en la cual hago lo que me gusta hacer que es andar en moto.",
                    name: "Pedro",
                    image: "pedro-plasencia.jpg",
                    alt: "mensajero pedro"
                }
            ]
        };
    },

    methods: {
        selectQuote($event) {
            this.slide = $event;
        }
    }
};
</script>

<style lang="scss">
$zubut-blue: #0077fe;

#drivers-testimonials-carousel {
    border-radius: 0.25rem;
    box-shadow: inset 0 -5px 0 0 #0077fe, 0 13px 12px 2px rgba(158, 198, 241, 0.25);

    .carousel-inner {
        border-radius: 0.25rem;
    }

    .carousel-indicators {
        bottom: -53px;
        li {
            margin: 0px;
            background-color: #8190a1;
            width: 50px;
            height: 6px;
            margin-right: -0.4px;

            &.active {
                background-color: $zubut-blue;
            }
        }
    }
}
</style>
