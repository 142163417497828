<template>
    <b-carousel-slide
        class="driver-testimonial-slide"
        :img-src="require(`@/assets/img/${testimonial.image}`)"
        :img-alt="testimonial.alt"
    >
        <img class="quotes" src="@/assets/icons/quotes.svg" alt="quotes" />
        <p class="text">{{ testimonial.text }}</p>
        <p class="name">- {{ testimonial.name }}</p>
    </b-carousel-slide>
</template>

<script>
import { BCarouselSlide } from "bootstrap-vue";

export default {
    name: "DriverTestimonialSlide",

    components: {
        BCarouselSlide
    },

    props: {
        testimonial: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss">
$zubut-blue: #0077fe;

.driver-testimonial-slide {
    .carousel-caption {
        padding: 0px;

        .quotes {
            height: 10px;
            filter: none;
        }

        .text {
            font-weight: bold;
        }

        .name {
            font-weight: bold;
            font-size: 0.75em;
            margin-bottom: 0px;
        }
    }

    img {
        filter: grayscale(1) brightness(70%);
    }

    @media only screen and (min-width: 768px) {
        .carousel-caption {
            text-align: left;

            .quotes {
                height: 1em;
                margin-bottom: 1em;
            }

            .text {
                font-size: 24px;
                margin-bottom: 2em;
            }

            .name {
                font-weight: bold;
                font-size: 1em;
                margin-bottom: 3em;
            }
        }
    }

    @media only screen and (min-width: 992px) {
        .carousel-caption {
            .text {
                font-weight: bold;
                font-size: 1.25em;
                margin-bottom: 1em;
            }
        }
    }
}
</style>
