<template>
    <div>
        <h2 class="mb-4">Formas de obtener tus ganancias con Zubut</h2>
        <b-row>
            <b-col cols="12" md="4">
                <b-card no-body class="mb-1 text-left accordion">
                    <b-button
                        class="text-left d-flex justify-content-between"
                        v-b-toggle.accordion-1
                    >
                        <span>Por horas trabajadas</span
                        ><span class="font-weight-bold when-closed">+</span>
                        <span class="font-weight-bold when-opened">-</span>
                    </b-button>
                    <b-collapse id="accordion-1" role="tabpanel">
                        <b-card-body>
                            <b-card-text class="text-justify">
                                Trabajamos con clientes a los que se les da servicio a través de
                                reservaciones por hora, es decir, ellos solicitan mensajero(s) por N
                                cantidad de horas al día. Estos servicios tienen una ganancia
                                aproximada de <b>80MXN por hora</b>.
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card no-body class="mb-1 text-left accordion">
                    <b-button
                        class="text-left d-flex justify-content-between"
                        v-b-toggle.accordion-2
                    >
                        <span>Por paquetes entregados</span
                        ><span class="font-weight-bold when-closed">+</span>
                        <span class="font-weight-bold when-opened">-</span>
                    </b-button>
                    <b-collapse id="accordion-2" role="tabpanel">
                        <b-card-body>
                            <b-card-text class="text-justify">
                                Con clientes que manejan un gran volumen de productos a entregar, se
                                maneja el servicio por paquete, en el que como mensajero, tendrás
                                una ganancia estimada de <b>120MXN por hora</b> entregando
                                aproximadamente de 6 a 8 paquetes.
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card no-body class="mb-1 text-left accordion">
                    <b-button
                        class="text-left d-flex justify-content-between"
                        v-b-toggle.accordion-3
                    >
                        <span>Por kilometraje recorrido</span
                        ><span class="font-weight-bold when-closed">+</span>
                        <span class="font-weight-bold when-opened">-</span>
                    </b-button>
                    <b-collapse id="accordion-3" role="tabpanel">
                        <b-card-body>
                            <b-card-text class="text-justify">
                                Por último, los servicios más comunes en nuestra plataforma, los de
                                costo por kilometro, manejamos una tarifa de
                                <b>9 pesos por kilómetro</b>, del cual como mensajero,
                                <b>obtendrás el 80%</b> del costo total de dicho servicio.
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BCard, BCardBody, BCardText, BCollapse, VBToggle } from "bootstrap-vue";

export default {
    name: "DriverEarnings",

    components: {
        BCard,
        BCardBody,
        BCardText,
        BCollapse
    },

    directives: {
        "b-toggle": VBToggle
    }
};
</script>

<style lang="scss" scoped>
$zubut-blue: #0077fe;

.accordion {
    button {
        background-color: $zubut-blue;
        border: none;
    }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}
</style>
